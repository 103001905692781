import React, {useEffect, useState} from 'react';
import '../../css/Table.css';
import '../../css/Alerts.css';
import useAxiosFunction from "../../hooks/useAxiosFunction.jsx";
import LoadingComponent from "../LoadingComponent.jsx";
import CustomTable from "../CustomTable.jsx";
import apiUrl from "../manager/ApiUrl.jsx";

const MembersTableComponent = ({records}) => {

    const columns = [
        {
            name: 'PlayerID',
            selector: row => row.id,
            sortable: true,
            maxWidth: '60px'
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
            maxWidth: '200px'
        },
        {
            name: 'UUID',
            selector: row => row.uuid,
            sortable: true,
            maxWidth: '290px'
        }
    ];

    const handleRowClick = (rowData) => {
        window.location.href = "/info/player/" + rowData.username
    };

    return (
        <>
            <CustomTable data={records} columns={columns} clickable={handleRowClick}/>
        </>
    );
};

export default MembersTableComponent;
