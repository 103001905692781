
import '../css/App.css';
import '../css/Header.css';
import {useState} from "react";

function Header() {
    const [text, setText] = useState("play.rebornmc.it");

    const items = [
        {id: 1, text: 'Home', path: '/home'},
        {id: 2, text: 'Area Admin', path: '/areaAdmin'}
    ];

    function copyIp() {
        const ip = "play.rebornmc.it";
        navigator.clipboard.writeText(ip);
        setText("Indirizzo Copiato!")
    }

    return (
        <header>
            <img src="/img/bgl.png" id="background" alt=""/>
            <div className="background_gradient" id="background"/>
            <a href="/" id="logo"><img id="logo" src="/img/logoReborn.png" alt=""/></a>

            <div className="info-box" id="info">
                <a href="https://store.rebornmc.it" style={{backgroundColor: "#ff3a51"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                         className="bi bi-bag-heart-fill store" viewBox="0 0 16 16" >
                        <path
                            d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5ZM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1Zm0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
                    </svg>
                </a>

                <p onClick={copyIp} id="server_ip">{text}</p>

                <a target="_blank" rel="noopener" href="https://discord.gg/BAAbDzv5VG"
                   style={{backgroundColor: "#7289DA"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                         className="bi bi-discord discord" viewBox="0 0 16 16">
                        <path
                            d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                    </svg>
                </a>
            </div>

            <div className="background-fade"/>
        </header>
    )
}

export default Header