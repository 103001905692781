import '../../css/Home.css';
import '../../css/Buttons.css';
import LoginForm from "../LoginForm.jsx";
import useToken from "../../hooks/useToken.jsx";
import useUsername from "../../hooks/useUsername.jsx";
import Navbar from "../Navbar.jsx";

function AdminLogin() {

    const {token, setToken, resetToken} = useToken();
    const {setUsername} = useUsername();

    return (
        <main style={{paddingTop: "10px"}}>
            <Navbar/>
            <h1>LOGIN - AREA ADMIN</h1>
            <LoginForm token={token} setToken={setToken} setUsername={setUsername}></LoginForm>
        </main>
    )
}

export default AdminLogin