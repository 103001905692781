import React, { useState } from 'react';
import '../css/Table.css';

const CustomTable = ({ data, columns, onPageChange, clickable}) => {
    const [pageIndex, setPageIndex] = useState(0);

    // Funzione per gestire il cambio di pagina
    const handlePageChange = direction => {
        window.scrollTo(0, 0);
        if (direction === 'prev' && pageIndex > 0) {
            setPageIndex(prevIndex => {
                prevIndex = prevIndex - 1
                onPageChange(prevIndex)
                return prevIndex
            });
        } else if (direction === 'next') {
            setPageIndex(prevIndex => {
                prevIndex = prevIndex + 1
                onPageChange(prevIndex)
                return prevIndex
            });
        }
    };

    return (
        <div className={"table-container"}>
            <table className={"dark-table"}>
                <thead>
                <tr>
                    {columns.map(column => (
                        <th key={column.name} style={{ maxWidth: column.maxWidth }}>
                            {column.name}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data && data.map((row, rowIndex) => (
                    <tr key={rowIndex} onClick={() => clickable(row)}>
                        {columns.map(column => (
                            <td key={column.name}>{column.selector(row)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            {/* Aggiungiamo i controlli della paginazione */}
            <div>
                <button onClick={() => handlePageChange('prev')} disabled={pageIndex === 0}>
                    Precedente
                </button>
                <span>Page {pageIndex + 1}</span>
                <button onClick={() => handlePageChange('next')}>
                    Successiva
                </button>
            </div>
        </div>
    );
};

export default CustomTable;
