import {useState} from 'react';

export default function useUsername() {
    const getUsername = () => {
        const usernameString = localStorage.getItem('username');
        return JSON.parse(usernameString)
    };

    const [username, setUsername] = useState(getUsername());

    const saveUsername = username => {
        localStorage.setItem('username', JSON.stringify(username));
        setUsername(username);
    };

    const resetUsername = function () {
        localStorage.removeItem('username')
    }

    return {
        setUsername: saveUsername,
        resetUsername: resetUsername,
        username
    }
}