import { useState, useEffect } from "react";
import axios from "axios";
import useToken from "./useToken.jsx";

const useAxiosFunction = () => {
    const [response, setResponse] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const { token, resetToken } = useToken();

    const axiosFetch = async (configObj) => {
        const { method, url, requestConfig = {} } = configObj;

        try {
            setLoading(true);

            const axiosInstance = axios.create({
                baseURL: url,
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (token) {
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }

            const res = await axiosInstance.request({
                method: method,
                data: requestConfig
            });

            setResponse(res.data);
            setStatus(res.status);
            setLoading(false);

            return res;

        } catch (err) {
            if (err.response && err.response.status === 401) {
                console.error("Error: ", err.response.data.error);
                setResponse(err.response.data);
                setStatus(err.response.status);
                setError(err.response.data.error);
                resetToken();
                window.location.href = "/adminlogin";
            } else {
                console.error("Other error:", err.message);
                setResponse(err.response.data);
                setStatus(err.response.status);
                setError(err.message);
                throw err;
            }
        } finally {
            setLoading(false);
        }
    };

    return [response, error, loading, status, axiosFetch];
};

export default useAxiosFunction;
