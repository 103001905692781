import React, {useEffect, useState} from 'react';
import '../css/Table.css';
import '../css/Alerts.css';
import useAxiosFunction from "../hooks/useAxiosFunction.jsx";
import LoadingComponent from "./LoadingComponent.jsx";
import CustomTable from "./CustomTable.jsx";
import apiUrl from "./manager/ApiUrl.jsx";

const CommandTableComponent = () => {
    const [records, setRecords] = useState([]);
    const [response, error, loading,status, fetchData] = useAxiosFunction();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            maxWidth: '60px'
        },
        {
            name: 'PlayerID',
            selector: row => row.playerId,
            sortable: true,
            maxWidth: '60px'
        },
        {
            name: 'Server',
            selector: row => row.serverName,
            sortable: true,
            maxWidth: '200px'
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
            maxWidth: '200px'
        },
        {
            name: 'UUID',
            selector: row => row.uuid,
            sortable: true,
            maxWidth: '290px'
        },
        {
            name: 'Data',
            selector: row => row.date,
            sortable: true,
            maxWidth: '250px'
        },
        {
            name: 'Comando',
            selector: row => row.command,
            sortable: true,
            maxWidth: '250px'
        },
    ];

    let [filters, setFilters] = useState({
        start: 0,
        end: 100,
        playerId: '',
        serverName: '',
        username: '',
        uuid: '',
        date: '',
        command: ''
    });

    useEffect(() => {
        fetchData({
            method: 'POST',
            url: apiUrl + "/logs/commands",
            requestConfig: {
                start: filters.start,
                end: 100,
                playerId: filters.playerId,
                serverName: filters.serverName,
                username: filters.username,
                uuid: filters.uuid,
                date: filters.date,
                command: filters.command
            }
        });
    }, []);

    useEffect(() => {
        setRecords(response.commands)
    }, [response]);

    const handlePageChange = newPage => {
        setFilters({
            ...filters,
            start: newPage * 100
        });
        fetchData({
            method: 'POST',
            url: apiUrl + "/logs/commands",
            requestConfig: {
                start: newPage * 100,
                end: filters.end,
                playerId: filters.playerId,
                serverName: filters.serverName,
                username: filters.username,
                uuid: filters.uuid,
                date: filters.date,
                command: filters.command
            }
        });
    };

    const handleFilterPlayerID = (event) => {
        setFilters({
            ...filters,
            playerId: event.target.value
        })
    }
    const handleFilterServerName = (event) => {
        setFilters({
            ...filters,
            serverName: event.target.value
        })
    }
    const handleFilterUsername = (event) => {
        setFilters({
            ...filters,
            username: event.target.value
        })
    }
    const handleFilterUUID = (event) => {
        setFilters({
            ...filters,
            uuid: event.target.value
        })
    }
    const handleFilterDate = (event) => {
        setFilters({
            ...filters,
            date: event.target.value
        })
    }
    const handleFilterCommand = (event) => {
        setFilters({
            ...filters,
            command: event.target.value
        })
    }

    const submitFilters = () => {
        fetchData({
            method: 'POST',
            url: apiUrl + "/logs/commands",
            requestConfig: {
                start: filters.start,
                end: filters.end,
                playerId: filters.playerId,
                serverName: filters.serverName,
                username: filters.username,
                uuid: filters.uuid,
                date: filters.date,
                command: filters.command
            }
        });
    }

    const handleRowClick = (rowData) => {
        window.location.href = "/info/player/" + rowData.username
    };

    return (
        <>
            <div style={{display: "flex"}}>
                <input className="search-input" type="text" onChange={handleFilterPlayerID} placeholder="PlayerID"
                       value={filters.playerId}/>
                <input className="search-input" style={{marginLeft: "20px"}} type="text"
                       onChange={handleFilterServerName} placeholder="Server" value={filters.serverName}/>
                <input className="search-input" style={{marginLeft: "20px"}} type="text"
                       onChange={handleFilterUsername} placeholder="Username" value={filters.username}/>
                <input className="search-input" style={{marginLeft: "20px"}} type="text" onChange={handleFilterUUID}
                       placeholder="UUID" value={filters.uuid}/>
                <input className="search-input" style={{marginLeft: "20px"}} type="text" onChange={handleFilterDate}
                       placeholder="Data" value={filters.date}/>
                <input className="search-input" style={{marginLeft: "20px"}} type="text"
                       onChange={handleFilterCommand} placeholder="Comando" value={filters.command}/>
                <button style={{marginLeft: "20px"}} onClick={submitFilters}>Applica filtri</button>
            </div>
            {loading && <LoadingComponent/>}
            {!loading && error && (
                <div className="alert alert-danger">
                    <strong>{error}</strong>
                    <button type="button">Chiudi</button>
                </div>
            )}

            {!loading && !error && !response && (
                <div className="alert alert-warning">
                    <strong>Non ci sono dati da visualizzare.</strong>
                    <button type="button">Chiudi</button>
                </div>
            )}
            <CustomTable data={records} columns={columns} onPageChange={handlePageChange} clickable={handleRowClick}/>

        </>
    );
};

export default CommandTableComponent;
