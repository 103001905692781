import '../../css/Home.css';
import '../../css/Buttons.css';
import '../../css/PlayerInfo.css';
import useToken from "../../hooks/useToken.jsx";
import Navbar from "../Navbar.jsx";
import React, {useEffect} from "react";
import apiUrl from "../manager/ApiUrl.jsx";
import useAxiosFunction from "../../hooks/useAxiosFunction.jsx";
import LoadingComponent from "../LoadingComponent.jsx";
import CustomTable from "../CustomTable.jsx";
import MembersTableComponent from "../oneblock/MembersTableComponent.jsx";

function InfoPlayer({username}) {

    const {token} = useToken();
    const [response, error, loading, status, fetchData] = useAxiosFunction();
    const [responseBan, errorBan, loadingBan, statusBan, fetchDataBan] = useAxiosFunction();
    const [responseOneblock, errorOneblock, loadingOneblock, statusOneblock, fetchDataOneblock] = useAxiosFunction();
    const [responseIslandMembers, errorIslandMembers, loadingIslandMembers, statusIslandMembers, fetchDataIslandMembers] = useAxiosFunction();
    const [responseIslandTrusts, errorIslandTrusts, loadingIslandTrusts, statusIslandTrusts, fetchDataIslandTrusts] = useAxiosFunction();


    useEffect(() => {
        fetchData({
            method: 'GET',
            url: apiUrl + "/playerdetails/" + username,
        });
        fetchDataBan({
            method: 'GET',
            url: apiUrl + "/bans/" + username,
        });
        fetchDataOneblock({
            method: 'GET',
            url: apiUrl + "/oneblock/getplayerisland/" + username,
        });
    }, []);

    useEffect(() => {
        if (!loadingOneblock && responseOneblock && responseOneblock.id) {
            fetchDataIslandMembers({
                method: 'GET',
                url: apiUrl + "/oneblock/getislandmembers/" + responseOneblock.id,
            });
            fetchDataIslandTrusts({
                method: 'GET',
                url: apiUrl + "/oneblock/getislandtrusts/" + responseOneblock.id,
            });
        }
    }, [responseOneblock]);

    useEffect(() => {
        if (!loadingIslandMembers && responseIslandMembers) {
            console.log("stampo i membri")
            console.log(responseIslandMembers.members)
        }
    }, [responseIslandMembers])


    if (!token) {
        return (
            window.location.href = "/adminlogin"
        )
    }

    const columns = [
        {
            name: 'Bannato',
            selector: row => row.banned ? "Si" : "Scaduto",
            sortable: true,
            maxWidth: '60px'
        },
        {
            name: 'Reason',
            selector: row => row.banReason,
            sortable: true,
            maxWidth: '60px'
        },
    ];

    const handleRowClick = (rowData) => {
    };

    return (
        <main style={{paddingTop: "10px"}}>
            <Navbar/>
            <h1>AREA ADMIN - INFO PLAYER: {username}</h1>
            {loading && <LoadingComponent/>}
            {!loading && error && (
                <div className="alert alert-danger">
                    <strong>{error}</strong>
                    <button type="button">Chiudi</button>
                </div>
            )}

            {!loading && !error && !response && (
                <div className="alert alert-warning">
                    <strong>Non ci sono dati da visualizzare.</strong>
                    <button type="button">Chiudi</button>
                </div>
            )}

            {!loading && !error && !response && (
                <div className="alert alert-warning">
                    <strong>Non ci sono dati da visualizzare.</strong>
                    <button type="button">Chiudi</button>
                </div>
            )}

            {!loading && !error && response && (
                <div className="container-player-info">
                    <div className="info">
                        <span>ID: {response.id}</span>
                    </div>
                    <div className="info">
                        <span>Username: {response.username}</span>
                    </div>
                    <div className="info">
                        <span>UUID: {response.uuid}</span>
                    </div>
                    <div className="info">
                        <span>IP: {response.ip}</span>
                    </div>
                    <div className="info">
                        <span>Data primo login: {response.firstJoinDate}</span>
                    </div>
                    <div className="info">
                        <span>Data ultimo login: {response.lastJoinDate}</span>
                    </div>
                    <br/>
                    <div className="info">
                        <span>Alts: {response.alts && response.alts.map((row, rowIndex) => (
                            <div>
                                <a href={`/info/player/${row}`}>->&nbsp;&nbsp;&nbsp;{row}</a>
                            </div>
                        ))}</span>
                    </div>

                    {loadingBan && <LoadingComponent/>}
                    {!loadingBan && errorBan && (
                        <div className="alert alert-danger">
                            <strong>{errorBan}</strong>
                            <button type="button">Chiudi</button>
                        </div>
                    )}

                    {!loadingBan && !errorBan && !responseBan && (
                        <div className="alert alert-warning">
                            <strong>Non ci sono dati da visualizzare.</strong>
                            <button type="button">Chiudi</button>
                        </div>
                    )}

                    {!loadingBan && !errorBan && !responseBan && (
                        <div className="alert alert-warning">
                            <strong>Non ci sono dati da visualizzare.</strong>
                            <button type="button">Chiudi</button>
                        </div>
                    )}

                    {!loadingBan && !errorBan && responseBan && (
                        <CustomTable data={responseBan} columns={columns} clickable={handleRowClick} title="Bans"/>
                    )}

                    {loadingOneblock && <LoadingComponent/>}
                    {!loadingOneblock && errorOneblock && (
                        <div className="alert alert-danger">
                            <strong>{errorOneblock}</strong>
                            <button type="button">Chiudi</button>
                        </div>
                    )}

                    <div style={{marginTop: "20px"}}></div>
                    <h2>-------&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ONEBLOCK &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-------</h2>

                    {!loadingOneblock && !errorOneblock && responseOneblock && (
                        <>
                            <div className="info">
                                <span>Id isola: {responseOneblock.id}</span>
                            </div>
                            <div className="info">
                                <span>Nome: {responseOneblock.islandName}</span>
                            </div>
                            <div className="info">
                                <span>Stage: {responseOneblock.stage}</span>
                            </div>
                            <div className="info">
                                <span>Tier: {responseOneblock.tier}</span>
                            </div>
                            <div className="info">
                                <span>Blocchi rotti: {responseOneblock.blocks}</span>
                            </div>
                            <div className="info">
                                <span>Banca: {responseOneblock.balance}</span>
                            </div>
                        </>
                    )}

                    {loadingIslandMembers && <LoadingComponent/>}
                    {!loadingIslandMembers && errorIslandMembers && (
                        <div className="alert alert-danger">
                            <strong>{errorIslandMembers}</strong>
                            <button type="button">Chiudi</button>
                        </div>
                    )}

                    {!loadingIslandMembers && !errorIslandMembers && responseIslandMembers && (
                        <div className="info">
                            <span>Membri isola:</span>

                            <MembersTableComponent records={responseIslandMembers.members}/>
                        </div>
                    )}

                    {loadingIslandTrusts && <LoadingComponent/>}
                    {!loadingIslandTrusts && errorIslandTrusts && (
                        <div className="alert alert-danger">
                            <strong>{errorIslandTrusts}</strong>
                            <button type="button">Chiudi</button>
                        </div>
                    )}

                    {!loadingIslandTrusts && !errorIslandTrusts && responseIslandTrusts && (
                        <div className="info">
                            <span>Trusts isola: </span>

                            <MembersTableComponent records={responseIslandTrusts.trusts}/>
                        </div>
                    )}

                </div>
            )}
        </main>
    )
}

export default InfoPlayer