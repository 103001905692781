import '../../css/Home.css';
import Navbar from "../Navbar.jsx";
import CommandTableComponent from "../CommandsTableComponent.jsx";
import useToken from "../../hooks/useToken.jsx";

function ChatLogs() {

    const {token} = useToken();

    if (!token) {
        return (
            window.location.href = "/adminlogin"
        )
    }

    return (
        <main style={{paddingTop: "10px"}}>
            <Navbar/>
            <h1>AREA ADMIN - CommandLogs</h1>
            <CommandTableComponent/>
        </main>
    )
}

export default ChatLogs