import '../../css/Home.css';
import ChatTableComponent from "../ChatTableComponent.jsx";
import Navbar from "../Navbar.jsx";
import useToken from "../../hooks/useToken.jsx";

function ChatLogs() {

    const {token} = useToken();

    if (!token) {
        return (
            window.location.href = "/adminlogin"
        )
    }

    return (
        <main style={{paddingTop: "10px"}}>
            <Navbar/>
            <h1>AREA ADMIN - ChatLogs</h1>
            <ChatTableComponent/>
        </main>
    )
}

export default ChatLogs