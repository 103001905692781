import '../../css/Home.css';
import '../../css/Buttons.css';
import useToken from "../../hooks/useToken.jsx";
import Navbar from "../Navbar.jsx";

function AdminArea() {

    const {token} = useToken();

    if (!token) {
        window.location.href = "/adminlogin"
        return;
    }

    return (
        <main style={{paddingTop: "10px"}}>
            <Navbar/>
            <h1>AREA ADMIN</h1>
            <h3>Area riservata allo staff.</h3>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <button className="button-one" onClick={() => window.location.href = "/logs/chat"}>Chat Logs
                </button>
                <button className="button-one" onClick={() => window.location.href = "/logs/commands"}>Command
                    logs
                </button>
                <button className="button-one"
                        onClick={() => window.location.href = "https://punishments.rebornmc.it"}>Punizioni
                </button>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5vh"}}>
                <button className="button-one" onClick={() => window.location.href = "/info/playerlist"}>Player
                    List
                </button>
            </div>

        </main>
    )

}

export default AdminArea