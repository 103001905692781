import '../../css/Home.css';
import '../../css/Rules.css';

function Rules() {

    return (
        <main>
            <div id="rules-sect" className="section">
                <div id="rules-center">
                    <img id="rules-image" src="/img/bgl.png" alt=""/>

                        <div className="article-wrapper">
                            <div className="section-title">
                                <h1 id="rules-title">Comportamenti non ammessi</h1>
                                <h1 id="game-name">Network</h1>
                            </div>

                            <ul id="rules">
                                <li id="first-rule">
                                    <span>Utilizzo di Cheat o Hacked Client.</span>
                                </li>
                                <li>
                                    <span>Utilizzo di Macro e/o Autoclicker.</span>
                                </li>
                                <li>
                                    <span>Utilizzo doppio mouse non ammesso.</span>
                                </li>
                                <li>
                                    <span>Bug Abusing.</span>
                                </li>
                                <li>
                                    <span>Minacce di DDoS e/o Dox.</span>
                                </li>
                                <li>
                                    <span>Attacchi DDoS e/o Dox.</span>
                                </li>
                                <li>
                                    <span>Utilizzare un altro account per eludere un ban.</span>
                                </li>
                                <li>
                                    <span>Spam di indirizzi IP/link di altri server.</span>
                                </li>
                                <li>
                                    <span>Spam di siti inappropriati.</span>
                                </li>
                                <li>
                                    <span>Condivisione account.</span>
                                </li>
                                <li>
                                    <span>Vietata la vendita di prodotti su piattaforme, interni o esterni. Che siano items/rank di RebornMC o altro.</span>
                                </li>
                                <li>
                                    <span>Falsificare prove per segnalare altri player.</span>
                                </li>
                                <li>
                                    <span>Impersonificare un membro dello Staff Team è categoricamente vietato.</span>
                                </li>
                                <li>
                                    <span>Qualsiasi tipo di riferimento alla vita reale di player per infastidire verrà punito con un ban.</span>
                                </li>
                                <li>
                                    <span>Abuso dei comandi (Se un giocatore abusa di comandi tali /report o /helpop).</span>
                                </li>
                                <li>
                                    <span>Build inappropriate.</span>
                                </li>
                                <li>
                                    <span>Skin inappropriata.</span>
                                </li>
                                <li>
                                    <span>Impersonificazione utente.</span>
                                </li>
                                <li>
                                    <span>Utilizzo VPN/Proxy.</span>
                                </li>
                            </ul>

                            <div className="section-title">
                                <h1 id="rules-title">Regolamento mute</h1>
                            </div>

                            <ul id="rules">
                                <li id="first-rule">
                                    <span>Linguaggio scurrile.</span>
                                </li>
                                <li>
                                    <span>Insulti allo staff.</span>
                                </li>
                                <li>
                                    <span>Insulti al server.</span>
                                </li>
                                <li>
                                    <span>Insulti.</span>
                                </li>
                                <li>
                                    <span>Tossicità in chat.</span>
                                </li>
                                <li>
                                    <span>Flood.</span>
                                </li>
                                <li>
                                    <span>Augurio di Morte.</span>
                                </li>
                                <li>
                                    <span>Spam di un social, come un canale YouTube/Twitch.</span>
                                </li>
                                <li>
                                    <span>Spam di qualsiasi sito non inerente a RebornMC.</span>
                                </li>
                                <li>
                                    <span>Discriminazioni di qualsiasi tipo sono SEVERAMENTE vietate.</span>
                                </li>
                            </ul>

                            <div className="section-title">
                                <h1 id="rules-title">Comportamenti Ammessi</h1>
                                <h1 id="game-name">Lifesteal</h1>
                            </div>

                            <ul id="rules">
                                <li id="first-rule">
                                    <span>Truffa in gioco.</span>
                                </li>
                                <li>
                                    <span>Tp kill.</span>
                                </li>
                                <li>
                                    <span>Tp Trap.</span>
                                </li>
                                <li>
                                    <span>Griefing di basi (se trovate in modo corretto).</span>
                                </li>
                            </ul>

                            <div className="section-title">
                                <h1 id="rules-title">Comportamenti Bannabili</h1>
                            </div>

                            <ul id="rules">
                                <li id="first-rule">
                                    <span>Acquistare / Vendere / Utilizzare coordinate di basi.</span>
                                </li>
                                <li>
                                    <span>Multi account.</span>
                                </li>
                                <li>
                                    <span>Xray (anche texture pack).</span>
                                </li>
                                <li>
                                    <span>F3 Pie (o trovare basi sfruttando f3).</span>
                                </li>
                                <li>
                                    <span>Nome del team inappropriato.</span>
                                </li>
                            </ul>

                            <div className="article-date">
                                Ultimo aggiornamento 25/02
                            </div>
                        </div>
                </div>
            </div>
        </main>
    )
}

export default Rules