import {useState} from 'react';
import isValidToken from "../components/manager/checkToken.jsx";
import axios from "axios";
import apiUrl from "../components/manager/ApiUrl.jsx";

/*export default function useToken() {
    const [isValidToken, setValidToken] = useState(true);

        const checkValidToken = async () => {
        try {
            const response = {
                status: 200
            }
            if (response.status === 200)
                setValidToken(true);
        } catch (error) {
            console.error(error);
            setValidToken(false)
        }
    }

    const getToken = () => {

        const tokenString = localStorage.getItem('token');
        return JSON.parse(tokenString)
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    const resetToken = function () {
        localStorage.removeItem('token')
    }

    return {
        setToken: saveToken,
        resetToken: resetToken,
        isValidToken: isValidToken,
        token
    }
}*/

export default function useToken() {
    const getCookie = name => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return atob(decodeURIComponent(parts.pop().split(';').shift())); // Decodifica Base64
    };

    const [token, setToken] = useState(getCookie('token'));

    const setCookie = (name, value, days) => {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
        }
        const encodedValue = encodeURIComponent(btoa(value)); // Codifica Base64
        document.cookie = `${name}=${encodedValue}${expires}; domain=.rebornmc.it; path=/`;
    };

    const saveToken = userToken => {
        setCookie('token', userToken, 365); // 365 giorni di scadenza
        setToken(userToken);
    };

    const resetToken = function () {
        setCookie('token', '', -1); // Scade subito
        setToken(null);
    };

    return {
        setToken: saveToken,
        resetToken: resetToken,
        token
    };
}
