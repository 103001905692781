import '../css/App.css';
import '../css/Footer.css';

function Footer() {

    return (
        <footer>
            <div className="background-fade fade-reverse"></div>
            <ul>
                <li>
                    <div className="footer-icon">
                        <img src="/icon/info-circle.svg" alt=""/>
                    </div>
                    <div className="footer-list-content">
                        <h2>Supporto</h2>
                        <p>
                            Se hai bisogni di assistenza apri un ticket su discord.
                            <a target="_blank" rel="noopener" href="https://discord.gg/BAAbDzv5VG">Clicca qui per entrare</a>
                        </p>
                    </div>
                </li>

                <li>
                    <div className="footer-icon">
                        <img src="/icon/envelope-at.svg" alt="" />
                    </div>

                    <div className="footer-list-content">
                        <h2 id="contatti">Contattaci</h2>
                        <p>
                            Puoi inviarci una mail all'indirizzo
                            <a href="mailto:support@rebornmc.it">
                                support@rebornmc.it
                            </a>
                        </p>
                    </div>
                </li>
            </ul>
        </footer>
    )
}

export default Footer