import '../../css/Home.css';

function Home() {

    return (
        <main>
            <div className="section">
                <div>
                    <div className="section-title">
                        <div></div>
                        <h1 id="notizie">Benvenuto su RebornMC!</h1>
                        <h3>Scopri tutte le ultime notizie del server:</h3>
                    </div>
                </div>

                <div className="wrapper-news">

                </div>
            </div>
        </main>
    )
}

export default Home