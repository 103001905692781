import './css/App.css'
import Home from "./components/pages/Home.jsx";
import {createBrowserRouter, RouterProvider, useParams} from "react-router-dom";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import Rules from "./components/pages/Rules.jsx";
import AdminArea from "./components/pages/AdminArea.jsx";
import ChatLogs from "./components/pages/ChatLogs.jsx";
import CommandLogs from "./components/pages/CommandLogs.jsx";
import PlayerList from "./components/pages/PlayerList.jsx";
import InfoPlayer from "./components/pages/InfoPlayer.jsx";
import AdminLogin from "./components/pages/AdminLogin.jsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <><Header/><Home></Home><Footer/></>,
    },
    {
        path: "/home",
        element: <><Header/><Home></Home><Footer/></>,
    },
    {
        path: "/rules",
        element: <><Header/><Rules></Rules><Footer/></>,
    },
    {
        path: "/adminarea",
        element: <><AdminArea></AdminArea></>,
    },
    {
        path: "/logs/chat",
        element: <><ChatLogs></ChatLogs></>,
    },
    {
        path: "/logs/commands",
        element: <><CommandLogs></CommandLogs></>,
    },
    {
        path: "/info/player/:player",
        element: <><InfoPlayerWrapper /></>,
    },
    {
        path: "/info/playerlist",
        element: <><PlayerList></PlayerList></>,
    },
    {
        path: "/adminlogin",
        element: <><AdminLogin></AdminLogin></>,
    },

])

function InfoPlayerWrapper() {
    let { player } = useParams();
    return <InfoPlayer username={player} />;
}


function App() {


    return (
        <RouterProvider router={router}/>
    )
}

export default App
