import '../css/Navbar.css';
import useToken from "../hooks/useToken.jsx";
import useUsername from "../hooks/useUsername.jsx";

function Navbar() {

    const {token, setToken} = useToken();
    const {setUsername} = useUsername();

    const logout = () => {
        setToken(null);
        setUsername(null);
        window.location.href = "/";
    }

    return (
        <div className="container">
            <div className="nav">
                <a href="/">Home</a>
                <a href="/adminArea">AdminArea</a>
                <a href="#" onClick={logout}>Logout</a>
            </div>
        </div>
    )
}

export default Navbar